<template>
  <div>
    <div class="bg-white shadow-sm rounded-lg p-4 mb-3">
      <el-table :data="licenseInfo" style="width: 100%">
        <el-table-column prop="corporate.name" label="Client"></el-table-column>
        <el-table-column
          prop="corporate.office.office_name"
          label="Office"
        ></el-table-column>
        <el-table-column
          prop="license_type.name"
          label="License Type"
        ></el-table-column>
        <el-table-column prop="issueDate" label="Issue Date" width="170">
          <template slot-scope="scope">{{
            scope.row.issueDate | moment("dd, MM Do YYYY")
          }}</template>
        </el-table-column>
        <el-table-column prop="dueDate" label="Due Date" width="170">
          <template slot-scope="scope">{{
            scope.row.dueDate | moment("dd, MM Do YYYY")
          }}</template>
        </el-table-column>
      </el-table>
    </div>
    <div class="bg-white shadow-sm rounded-lg p-4">
      <div class="row justify-content-center">
        <h4>Report</h4>
      </div>
      <el-table :data="tableData" class="mt-3" style="width: 100%">
        <el-table-column
          sortable
          prop="description"
          label="Description"
        ></el-table-column>
        <el-table-column sortable prop="cost" label="Cost">
          <template slot-scope="scope">${{ scope.row.cost }}.00</template>
        </el-table-column>
        <el-table-column sortable prop="fees" label="Fees">
          <template slot-scope="scope">${{ scope.row.fees }}.00</template>
        </el-table-column>
        <el-table-column sortable prop="total" label="Total">
          <template slot-scope="scope">${{ scope.row.total }}.00</template>
        </el-table-column>
        <el-table-column
          sortable
          prop="processed.name"
          label="Processed By"
        ></el-table-column>
        <el-table-column sortable prop="date" label="Date">
          <template slot-scope="scope">{{
            scope.row.date | moment("dd, MM Do YYYY")
          }}</template>
        </el-table-column>
        <el-table-column
          width="230"
          v-if="this.$store.getters.discriminator === 'administrator'"
        >
          <template slot-scope="scope">
            <center>
              <el-button-group>
                <el-button
                  size="mini"
                  icon="el-icon-edit"
                  @click="update(scope.row, scope.$index)"
                ></el-button>
              </el-button-group>
            </center>
          </template>
        </el-table-column>
      </el-table>
      <el-button class="fixed-bottom new-register" type="success" @click="add"
        >Add New Transaction</el-button
      >
      <el-button
        class="fixed-bottom new-register"
        style="margin-left:160px;"
        type="primary"
        @click="back"
        >Back To Licenses</el-button
      >

      <el-drawer
        title="Transaction"
        :visible.sync="component.drawer"
        direction="rtl"
        size="500px"
      >
        <div class="pt-3 pl-5 pr-5 pb-5">
          <component-transaction
            v-on:refresh="load($event)"
            :data="component.data"
            :license="licenseId"
            :key="component.render"
          />
        </div>
      </el-drawer>
    </div>
  </div>
</template>
<script>
import ComponentTransaction from "@/components/admin/Transaction";
import licenseTransaction from "@/services/api/licenseTransaction";

export default {
  components: {
    ComponentTransaction,
  },
  props: {
    license: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      search: "",
      tableData: [],
      discriminator: "",
      licenseId: null,
      component: {
        data: null,
        drawer: false,
        render: 0,
      },
      index: null,
    };
  },
  watch: {
    license: {
      immediate: true,
      deep: true,
      handler(val, oldVal) {
        this.licenseId = val.id;
        if (val !== null) {
          licenseTransaction.get(val.id).then((response) => {
            this.tableData = response;
          });
        }
      },
    },
  },
  computed: {
    licenseInfo() {
      if (this.license) return [this.license];
      return [];
    },
  },
  mounted() {},
  methods: {
    back() {
      this.$emit("back");
    },
    load(event) {
      this.component.drawer = false;
      if (event.update) {
        this.tableData.splice(this.index, 1, event.data);
      } else {
        this.tableData.unshift(event.data);
      }
    },
    add() {
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = null;
    },
    update(row, index) {
      this.index = index;
      //
      this.component.drawer = true;
      this.component.render += 1;
      this.component.data = row;
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style lang="scss">
th,
td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
</style>
