import axios from 'axios'

export default {
    async get(license) {
        try {
            const response = await axios.get(`/license/transactions/of/${license}`);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async create(data) {
        try {
            const response = await axios.post('/license/transactions', data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },
    async update(data) {
        try {
            const response = await axios.put(`/license/transactions/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    },async delete(data) {
        try {
            const response = await axios.put(`/license/transactions/delete/${data.id}`, data);
            return response.data
        }
        catch (error) {
            return Promise.reject();
        }
    }
}
