<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item :label="$t('Description')" prop="status">
        <el-select
          v-model="form.description"
          placeholder="Select Description"
          class="w-100"
        >
          <el-option value="New application" label="New Application">New Application</el-option>
          <el-option value="Renewal" label="Renewal">Renewal</el-option>
        </el-select>
      </el-form-item>
      <el-form-item
        :label="$t('Cost')"
        prop="cost"
        :rules="[{ required: true, message: 'Cost is required' }]"
      >
        <el-input type="text" v-model="form.cost" @change="total"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('Fees')"
        prop="fees"
        :rules="[{ required: true, message: 'Fees is required' }]"
      >
        <el-input type="text" v-model="form.fees" @change="total"></el-input>
      </el-form-item>
      <el-form-item
        :label="$t('Total')"
        prop="total"
      >
        <el-input type="text" v-model="form.total" disabled></el-input>
      </el-form-item>

      <el-button
        type="primary"
        @click="!data ? submitForm('form') : updateForm('form')"
        >Save Transaction</el-button
      >
    </el-form>
  </div>
</template>

<script>
import licenseTransaction from "@/services/api/licenseTransaction";
export default {
  props: ["data","license"],
  data() {
    return {
      offices: [],
      users: [],
      corporates: [],
      licenseTypes: [],
      form: {
        id: null,
        license: this.license,
        description: null,
        cost: null,
        fees: null,
        total: null,
        processedBy: this.$store.getters.id
      },
    };
  },
  mounted() {
    //
    if (this.data) {
      this.form.id = this.data.id;
      this.form.description = this.data.description;
      this.form.cost = this.data.cost
      this.form.fees = this.data.fees;
      this.form.total = this.data.total;
    }
  },
  methods: {
    total() {
      this.form.total = parseInt(this.form.cost) + parseInt(this.form.fees) 
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          licenseTransaction
            .create(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          licenseTransaction
            .update(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.message,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss"></style>
